.features-tiles {
    background: get-color(light, 2);
    text-align: center;
}


.features-tiles-item-image {
    display: inline-flex;
	border-radius: 50%;
    background: get-color(primary, 1);

}

.features-tiles-item-header {
  background: get-color(light, 2);
}


.features-tiles-item-content {
    background: get-color(light, 2);
    h4 {
        @include font-family(heading);
    }
}

