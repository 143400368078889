.site-footer {
    &.invert-color {
        color: get-color(light, 2);
    }
}


.footer-bottom {
    padding-top: 0;
    a {
    .invert-color & {
      color: get-color(light, 2);
      }
    }
  }


.footer-top {
    padding-bottom: 0;
  }


.footer-nav {
    a {
      font-weight: bold;
      }
  }
